import React, { useState } from "react";
import "./CompletedTestsList.css"; // CSS for styling
import * as moment from "moment";
import {
  CCol,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
} from "@coreui/react";
import TestModal from "./TestModal";

const CompletedTestsList = ({ tests, quizCompleted }) => {
  const [isTestModalVisible, setIsTestModalVisible] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  return (
    <>
      {selectedTest && (
        <CModal
          visible={isTestModalVisible}
          onClose={() => setIsTestModalVisible(false)}
        >
          <CModalHeader closeButton>
            <CModalTitle style={{ textAlign: "center" }}>
              <p
                style={{
                  color:
                    selectedTest.score >= 20
                      ? "rgba(0, 128, 0, 0.6)"
                      : "rgba(255, 0, 0, 0.6)",
                }}
              >
                {selectedTest.score}/30
              </p>
              {moment(selectedTest.createdAt).format("YYYY-MM-DD HH:mm")}
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <TestModal test={selectedTest} />
          </CModalBody>
          <CModalFooter>
            <button
              className="btn btn-ok"
              onClick={() => {
                setIsTestModalVisible(false);
              }}
            >
              Înapoi
            </button>
          </CModalFooter>
        </CModal>
      )}
      <CCol xs>
        <CTable align="middle" className="mb-0 border" hover responsive>
          {tests.length ? (
            <>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Nr</CTableHeaderCell>
                  <CTableHeaderCell>Punctaj</CTableHeaderCell>
                  <CTableHeaderCell>Dată</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <tbody>
                {tests.map((item, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{index}</CTableDataCell>
                    <CTableDataCell>
                      <p
                        style={{
                          color:
                            item.score >= 20
                              ? "rgba(0, 128, 0, 0.6)"
                              : "rgba(255, 0, 0, 0.6)",
                        }}
                      >
                        {item.score} / 30
                      </p>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div>
                        {moment(item.createdAt).format("YYYY-MM-DD HH:MM")}
                      </div>
                      <button
                        onClick={() => {
                          setIsTestModalVisible(true);
                          setSelectedTest(item);
                        }}
                        className="btn-ok"
                      >
                        Detalii
                      </button>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </tbody>
            </>
          ) : (
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableRow>
                <CTableDataCell>
                  Ne cerem scuze drag vânător, am implementat memorarea testelor
                  efectuate în platforma începând cu 01-11-2024. Pare că nu ai
                  realizat încă niciun test de la această dată. Mult succes!
                </CTableDataCell>
              </CTableRow>
            </CTable>
          )}
        </CTable>
      </CCol>
    </>
  );
};

export default CompletedTestsList;
