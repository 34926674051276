import React, { useState, useEffect } from 'react';
import axios from '../../services/apiService';
import backgroundImage from '../../assets/background_smoking.jpg';
import { useSearchParams } from "react-router-dom";
import moment from 'moment';
import TextField from "@mui/material/TextField";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Exemplare = () => {
  const [exemplare, setExemplare] = useState([]);
  const [searchParams] = useSearchParams();
  const numeExemplar = searchParams.get("exemplar");
  const [intrebariExemplar, setIntrebariExemplar] = useState(null);
  const [backgroundHeight, setBackgroundHeight] = useState('auto');
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchInput(newValue);
    axios.post(`${process.env.REACT_APP_API_URL}/exemplare`, {
      input: newValue
    })
    .then(response => {
      setExemplare(response.data.exemplare);
    })
    .catch( error => {

    })
  };

  const setHeight = () => {
    const navbar = document.querySelector('nav');
    const stickyDiv= document.querySelector('.chapter-selector');

    if (navbar && stickyDiv) {
      const navbarHeight = navbar.offsetHeight;
      stickyDiv.style.top = navbarHeight + 'px';
    }

    const content = document.querySelector('.content');

    if (stickyDiv && content) {
      const stickyDivHeight = stickyDiv.offsetHeight + 10;
      content.style.top = stickyDivHeight + 'px';
    }
  }

  const handleClick = (item) => {
    axios.get(`${process.env.REACT_APP_API_URL}/exemplare/intrebari-related/${item.nume}`)
    .then( response => {
      setIntrebariExemplar(response.data.questions);
    })
    .catch(error => {

    })
    navigate(`/exemplare?exemplar=${item.nume}`);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/exemplare`)
    .then(response => {
      setExemplare(response.data.exemplare);
    })
    .catch( error => {

    });

    setHeight();

    if (numeExemplar) {
      axios.get(`${process.env.REACT_APP_API_URL}/exemplare/intrebari-related/${numeExemplar}`)
      .then( response => {
        setIntrebariExemplar(response.data.questions);
      })
      .catch(error => {

      })
    }
  }, []);

  useEffect(() => {

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handlePopstate = () => {
   setIntrebariExemplar(null);
  };


  useEffect(() => {
    if (intrebariExemplar && intrebariExemplar.length < 2 && window.innerWidth < 800 ) {
      setBackgroundHeight('100vh');
    }
    else if(intrebariExemplar && intrebariExemplar.length >= 2 && window.innerWidth < 800 ) {
      setBackgroundHeight('auto');
    }
    else if (window.innerWidth < 800 && exemplare.length < 2) {
      setBackgroundHeight('100vh');
    }
    else if (window.innerWidth < 800) {
      setBackgroundHeight('auto');
    }

    if (intrebariExemplar && intrebariExemplar.length < 5 && window.innerWidth > 800) { 
      setBackgroundHeight('100vh');
    }
    else if (window.innerWidth > 800 && intrebariExemplar && intrebariExemplar.length >= 5){
      setBackgroundHeight('auto');
    }
    else if (window.innerWidth > 800 && exemplare.length < 5) {
      setBackgroundHeight('100vh');
    }
    else if (window.innerWidth > 800) {
      setBackgroundHeight('auto');
    }
     
  }, [intrebariExemplar, exemplare]);

  return (
    <div className='custom-background' style={{ backgroundImage: `url(${backgroundImage})`, '--height': backgroundHeight }}>
      <Helmet>
        <title>Colecția Faunei de Vânătoare din România</title>
        <meta name="description" content="Descoperă bogăția diversității cinegetice a României: de la cerb și mistreț, la fazan și iepure. Ghidul complet al speciilor disponibile pentru vânătoare în această țară fascinantă." />
        <meta name="keywords" content="fauna cinegetică, vânătoare, specii de vânătoare, România, cerb, mistreț, fazan, iepure, becațina comună, becațina mică, bizam, cioară, enot, caprior, dihor, gaiță, graur" />
        <meta property="og:title" content="Colecția Faunei de Vânătoare din România" />
        <meta property="og:description" content="Descoperă bogăția diversității cinegetice a României: de la cerb și mistreț, la fazan și iepure. Ghidul complet al speciilor disponibile pentru vânătoare în această țară fascinantă." />
        <meta name="twitter:title" content="Colecția Faunei de Vânătoare din România" />
        <meta name="twitter:description" content="Descoperă bogăția diversității cinegetice a României: de la cerb și mistreț, la fazan și iepure. Ghidul complet al speciilor disponibile pentru vânătoare în această țară fascinantă." />
        <link rel="canonical" href="https://examenvanator.com/exemplare" />
        <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
    </Helmet>
      {!numeExemplar ?
      <div className='chapter-selector'>
        <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            label="Search"
            value={searchInput}
            style={{backgroundColor: 'rgba(22, 48, 32, 0.7)', width: '100%', padding: '10px'}}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: {
                color: 'white',
                borderColor: 'white',
              },
              inputProps: {
                style: { color: 'white' },
              },
            }}
            onChange={handleInputChange}
          /> 
        </div>
      : <></>}
 
  <div className='container-exemplare' >
    {!numeExemplar ? exemplare.map((item, index) => {
      const currentDate = new Date();
      const lastDate = new Date(item.perioadaFinal);
      const startDate = new Date(item.perioadaInceput);
      const isPastDate =  currentDate > lastDate || currentDate < startDate ? false : true;
      return (
        <div className={isPastDate ? 'custom-box past-date' : 'custom-box'}
          onClick={() => {handleClick(item)}}
          key={index}>
          <p className="title">{item.nume}</p>
          <div className="image-container">
            <img src={item.imagineUrl} alt="Your Image" className='imagine-exemplar'/>
          </div>
          <p className="description">Dată inceput: {moment(item.perioadaInceput).format("DD-MM-YYYY")}</p>
          <p className="description">Dată final: {moment(item.perioadaFinal).format("DD-MM-YYYY")}</p>
        </div>
      )
    }) : intrebariExemplar !== null && intrebariExemplar?.length ? 
      <>
      <div style={{color: 'white', backgroundColor: 'rgba(22, 48, 32, 0.7)', width: '100%', padding: '10px', position: 'sticky', top: '7vh', zIndex: '2'}}>
        <h2>{numeExemplar}</h2>
      </div>
      {intrebariExemplar.map((question, index) => {
        return (
        <>
          <div className={question?.answers && !question?.answers[0].image ? 'custom-box-answer-no-img' : 'custom-box-answer'}
              key={index}>
              <p>{question.question}</p>
              {question.image && (
                <img src={question.imageUrl} alt={`Imagine pentru întrebare ${index + 1}`} />
              )}
              <div>
                {question.answers.map((answer, answerIndex) => (
                  <div
                    key={answer._id}
                    style={{
                      listStyleType: 'none',
                      background:
                        answer.isCorrect  ? 'rgba(0, 128, 0, 0.6)' 
                        : ''
                    }}
                  >
                    <label>
                      {answer.text}
                      {answer.imageUrl && (
                        <img className='imagine-raspuns' src={answer.imageUrl} alt={`Imagine pentru răspunsul ${answer.text}`} />
                      )}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            
          </>
          )})}</> :
          <div class="custom-box-answer-no-img">
            <p>Din păcate, sistemul nostru nu a putut găsi întrebări direct conectate cu specia aleasă.</p>
          </div>
        } 
        </div>
      </div>

  );
};

