import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "../../store";
import moment from "moment";
import backgroundImage from "../../assets/background_smoking.jpg";
import {
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CModalFooter,
} from "@coreui/react";
import axios from "../../services/apiService";
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CapitolsTable from "../CapitolsTable/CapitolsTable";
import CompletedTestsList from "../CompletedTestsList/CompletedTestsList";

export const Profil = () => {
  const [user, setUser] = useState(null);
  const [confirmReset, setConfirmReset] = useState(false);
  const [viewTest, setViewTest] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const authToken = useSelector((state) => state.authTokenVanator);
  const [backgroundHeight, setBackgroundHeight] = useState("auto");
  const [capitole, setCapitole] = useState([
    "Capitol 1 - Biologia Speciilor de faună cinegetică",
    "Capitol 2 - Legislație în domeniul cinegetic",
    "Capitol 3 - Etică vânătorească",
    "Capitol 4 - Chinologie",
    "Capitol 5 - Boli ale vânatului",
    "Capitol 6 - Arme, Muniții și echipamente de vânătoare",
    "Capitol 7 - Managementul Speciilor de interes cinegetic",
    "Capitol 8 - Organizarea si practicarea vânătorii",
  ]);
  const totalIntrebari = {
    1: 522,
    2: 97,
    3: 38,
    4: 33,
    5: 21,
    6: 43,
    7: 121,
    8: 126,
  };
  const [userTests, setUserTests] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/get-profile`)
      .then((respose) => {
        setUser(respose.data.user[0]);
      })
      .catch((error) => {});
  }, []);

  const _confirmDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/user/reseteaza-capitol?capitol=${selectedCategory}`
      )
      .then((response) => {
        setConfirmReset(false);
        setSelectedCategory(null);
        axios
          .get(`${process.env.REACT_APP_API_URL}/user/get-profile`)
          .then((respose) => {
            setUser(respose.data.user[0]);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        setConfirmReset(false);
        setSelectedCategory(null);
        axios
          .get(`${process.env.REACT_APP_API_URL}/user/get-profile`)
          .then((respose) => {
            setUser(respose.data.user[0]);
          })
          .catch((error) => {});
      });
  };

  const setAddaptiveBackground = () => {
    if (userTests.length < 2 && window.innerWidth < 800) {
      setBackgroundHeight("100vh");
    } else if (userTests.length >= 2 && window.innerWidth < 800) {
      setBackgroundHeight("auto");
    } else if (window.innerWidth < 800 && userTests.length < 2) {
      setBackgroundHeight("100vh");
    } else if (window.innerWidth < 800) {
      setBackgroundHeight("auto");
    }

    if (userTests.length < 5 && window.innerWidth > 800) {
      setBackgroundHeight("100vh");
    } else if (window.innerWidth > 800 && userTests.length >= 5) {
      setBackgroundHeight("auto");
    } else if (window.innerWidth > 800 && userTests.length < 5) {
      setBackgroundHeight("100vh");
    } else if (window.innerWidth > 800) {
      setBackgroundHeight("auto");
    }
  };

  const loaduserTests = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/get-profile-tests`)
      .then((respose) => {
        setUserTests(respose.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setAddaptiveBackground();

    if (viewTest) {
      loaduserTests();
    }
  }, [viewTest]);

  return !authToken ? (
    <Navigate replace to="/intrebari" />
  ) : (
    <div
      className="custom-background"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        "--height": backgroundHeight,
      }}
    >
      <CModal visible={confirmReset} onClose={() => setConfirmReset(false)}>
        <CModalHeader onClose={() => setConfirmReset(false)}>
          <CModalTitle>Sunteți sigur/ă?!</CModalTitle>
        </CModalHeader>
        <CModalBody>
          Urmează să resetați tot progresul pentru categoria selectată, sunteți
          sigur/ă?
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setConfirmReset(false)}>
            Anulează
          </CButton>
          <CButton color="primary" onClick={() => _confirmDelete()}>
            Da, sunt sigur/ă
          </CButton>
        </CModalFooter>
      </CModal>
      <div className="profile-container">
        <h2>Informații Profil</h2>
        <div className="profile-details">
          <div>
            <strong>Nume:</strong> {user ? user.firstName : ""}{" "}
            {user ? user.lastName : ""}
          </div>
          <div>
            <strong>Email:</strong> {user ? user.email : ""}
          </div>
          <div>
            <strong>Dată creare cont:</strong>{" "}
            {user ? moment(user.createdAt).format("YYYY-MM-DD") : ""}
          </div>
        </div>
        <div>
          <strong>Total teste realizate:</strong>{" "}
          {user ? user.totalTesteRealizate : ""}
        </div>
        <div>
          <strong>Procent teste promovate:</strong>
          {user
            ? isNaN(user.totalTestePromovate / user.totalTesteRealizate) ||
              user.totalTesteRealizate === 0
              ? "0%"
              : (
                  (user.totalTestePromovate / user.totalTesteRealizate) *
                  100
                ).toFixed(2) + "%"
            : ""}
        </div>
        <button
          onClick={() => {
            setViewTest(!viewTest);
          }}
          className="btn-ok"
        >
          {viewTest ? "Vezi capitole" : "Vezi teste"}
        </button>
      </div>
      {viewTest ? (
        <CompletedTestsList tests={userTests} />
      ) : (
        <CapitolsTable
          user={user}
          capitole={capitole}
          totalIntrebari={totalIntrebari}
          setSelectedCategory={setSelectedCategory}
          setConfirmReset={setConfirmReset}
        />
      )}
    </div>
  );
};
