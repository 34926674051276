import React from "react";
import {
  CCol,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
} from "@coreui/react";
import { NavLink } from "react-router-dom";

const CapitolsTable = ({
  user,
  capitole,
  totalIntrebari,
  setSelectedCategory,
  setConfirmReset,
}) => {
  return (
    <CCol xs>
      <CTable align="middle" className="mb-0 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell>Capitol</CTableHeaderCell>
            <CTableHeaderCell>Intrebări Parcurse</CTableHeaderCell>
            <CTableHeaderCell>Procent</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <tbody>
          {user &&
            Object.entries(user.combinedCapitols).map((item, index) => (
              <CTableRow key={index}>
                <CTableDataCell>
                  <NavLink to={`/intrebari?capitol=${index + 1}`}>
                    {capitole[index]}
                  </NavLink>
                </CTableDataCell>
                <CTableDataCell>
                  {item[1].length} / {totalIntrebari[index + 1]}
                </CTableDataCell>
                <CTableDataCell>
                  <div>
                    {(
                      (item[1].length / totalIntrebari[index + 1]) *
                      100
                    ).toFixed(2)}{" "}
                    %
                  </div>
                  <button
                    onClick={() => {
                      setSelectedCategory(index + 1);
                      setConfirmReset(true);
                    }}
                    className="btn-ok"
                  >
                    Resetează
                  </button>
                </CTableDataCell>
              </CTableRow>
            ))}
        </tbody>
      </CTable>
    </CCol>
  );
};

export default CapitolsTable;
