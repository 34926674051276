import React, {useEffect, useState} from 'react'
import { AppContent } from '../index'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DefaultLayout = () => {
  return (
      <div>
          <div className="body flex-grow-1 px-3">
            <ToastContainer theme="colored" />
            <AppContent />
          </div>
        </div>
  )
}

export default DefaultLayout
