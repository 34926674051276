import { useState } from "react";

const Login = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleClosePopup = () => {
    // Close the popup when the close button is clicked
    setIsPopupVisible(false);
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {/* Popup */}
      {isPopupVisible && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <h2>Login Popup</h2>
            {/* Your login form for the popup */}
            <button onClick={handleClosePopup}>Close Popup</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
