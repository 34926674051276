import {
  CCol,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
} from "@coreui/react";

const TestModal = ({ test }) => {
  return (
    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
      <CTableBody>
        {test.questions.map((question, index) => (
          <CTableRow key={question._id}>
            <CTableDataCell>
              <div>
                <p>
                  {index + 1}. {question.question}
                </p>
                {question.image && (
                  <img
                    src={question.imageUrl}
                    alt={`Imagine pentru întrebare ${index + 1}`}
                  />
                )}
                <ul>
                  {question.answers.map((answer, answerIndex) => (
                    <li
                      key={answer._id}
                      style={{
                        background: answer.isCorrect
                          ? "rgba(0, 128, 0, 0.6)" // Correct answer color (green)
                          : question?.answered === answerIndex
                          ? "rgba(255, 0, 0, 0.6)" // Wrong selected answer color (red)
                          : "",
                      }}
                      className="answer-list-item"
                    >
                      <label style={{ display: "block", padding: "5px" }}>
                        <input
                          type="checkBox"
                          name={`question${index + 1}`}
                          checked={question?.answered === answerIndex}
                          // disabled={quizCompleted}
                        />
                        {answer.text}
                        {answer.imageUrl && (
                          <img
                            className="imagine-raspuns"
                            src={answer.imageUrl}
                            alt={`Imagine pentru răspunsul ${answer.text}`}
                          />
                        )}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </div>
  );
};

export default TestModal;
