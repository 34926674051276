import React from 'react';
import backgroundImage from '../../assets/background_smoking.jpg';
import { Helmet } from 'react-helmet';

const Privacy = () => (
    <div className='custom-background-home' style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
      <title>Privacy</title>
      <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
    </Helmet>
    <div className="introduction">
    <h1>Privacy Policy</h1>

    <h2>Information We Collect</h2>
    <p>We may collect personal information that you provide to us, such as your name and email address.</p>

    <h2>Use of Information</h2>
    <p>We may use the information we collect to: Provide and improve our services.</p>

    <h2>Sharing of Information</h2>
    <p>We may share your personal information with third-party service providers who perform services on our behalf.</p>

    <h2>Security</h2>
    <p>We take reasonable measures to protect your personal information from unauthorized access.</p>

    <h2>Your Choices</h2>
    <p>You may opt-out of receiving promotional emails from us by following the instructions in those emails.</p>

    <h2>Changes to this Policy</h2>
    <p>We may update this Privacy Policy from time to time.</p>

    <h2>Managing Your Account and Data Sharing</h2>
    <p>You can manage access to your Google Account and control how your data is shared through your account settings. For more information on how Google helps users share their data safely, please refer to this <a href="https://support.google.com/accounts/answer/3118621?hl=en" target="_blank" rel="noopener noreferrer">Google Support Article</a>.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about our Privacy Policy, please contact us by <a href="mailto:examenvanator@gmail.com">email</a>.</p>
  </div>
  </div>
);

export default Privacy;
