import backgroundImage from "../../assets/background_smoking.jpg";
import { Helmet } from "react-helmet";
export const Home = () => {
  return (
    <div
      className="custom-background-home"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Helmet>
        <title>Ghidul Faunei Cinegetice din România | ExamenVanator</title>
        <meta
          name="description"
          content="Explorează diversitatea faunei cinegetice din România prin intermediul examenelor de vânătoare oferite de ExamenVanator. Alătură-te comunității noastre de vânători pasionați și descoperă bogăția naturii românești."
        />
        <meta
          name="keywords"
          content="fauna cinegetică, România, examen de vânătoare, vânătoare, vanator, test cinegetic"
        />
      </Helmet>
      <div className="overlay">
        <div className="introduction">
          <h1 className="main-title">
            Bine ați venit pe site-ul nostru de pregătire pentru examenul de
            vânător!
          </h1>
          <p className="intro-text">
            Acesta este locul unde veți găsi toate resursele necesare pentru a
            vă pregăti în mod eficient și complet pentru examenul de vânător.
            Întrebările prezentate aici sunt cele oficiale, oferite de
            Ministerul Mediului, Apelor și Pădurilor.
          </p>
          <p className="intro-text">
            Acestea pot fi descărcate accesând{" "}
            <a
              href="https://mmediu.ro/app/webroot/uploads/files/Set%20de%20%C3%AEntreb%C4%83ri%20%C8%99i%20r%C4%83spunsuri%20privind%20examenul%20de%20v%C3%A2n%C4%83tor%20actualizate%20conform%20Legii%20nr.%20171%20din%202022.doc"
              className="link"
            >
              site-ul oficial al Ministerului Mediului, Apelor și Pădurilor
            </a>
            .
          </p>
          <section className="section">
            <h2 className="section-title">Exemplare</h2>
            <p>
              Secțiunea de Exemplare ilustrează toate speciile Faunei Cinegetice
              prezente în Romania împreună cu perioada aferentă de vânătoare.
              Prin selectarea unei specii de vănat, sistemul nostru va încerca
              sa găsească întrebări direct conectate cu specia aleasă.
            </p>
          </section>
          <section className="section">
            <h2 className="section-title">Mediu de Învățare</h2>
            <p>
              În secțiunea Mediu de Învățare puteți parcurge toate întrebările
              prezentate mai sus, structurate pe capitole pentru o mai bună
              înțelegere. Aceasta opțiune este disponibilă doar utilizatorilor
              autentificați.
            </p>
          </section>
          <section className="section">
            <h2 className="section-title">Test Oficial</h2>
            <p>
              Aceasta secțiune oferă spre testarea cunoștințelor un test sub
              formatul oficial. La final se oferă punctajul obținut, iar
              progresul fiecărui utilizator este salvat.
            </p>
          </section>
          <p className="intro-text">
            Pentru orice nelămurire, vă stăm la dispoziție prin{" "}
            <a href="mailto:examenvanator@gmail.com" className="link">
              email
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
