import React from 'react';
import backgroundImage from '../../assets/background_smoking.jpg';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
    <div className='custom-background-home' style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
      <title>Terms of service</title>
      <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
    </Helmet>
    <div className="introduction">
    <h1>Terms of Service</h1>

    <h2>Acceptance of Terms</h2>
    <p>By accessing and using https://examenvanator.com/#/home, you accept and agree to be bound by the terms and provision of this agreement.</p>

    <h2>Use of Site</h2>
    <p>https://examenvanator.com/#/home may be used for your personal use and not for any commercial purposes.</p>

    <h2>Intellectual Property</h2>
    <p>All content included on this site is the property of https://examenvanator.com/#/home or its content suppliers and protected by international copyright laws.</p>

    <h2>Limitation of Liability</h2>
    <p>In no event shall https://examenvanator.com/#/home or its associates be liable for any damages whatsoever resulting from the use or inability to use the website or the material on the website.</p>

    <h2>Changes to Terms</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>

    <h2>Managing Your Account and Data Sharing</h2>
    <p>You can manage access to your Google Account and control how your data is shared through your account settings. For more information on how Google helps users share their data safely, please refer to this <a href="https://support.google.com/accounts/answer/3118621?hl=en" target="_blank" rel="noopener noreferrer">Google Support Article</a>.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us by <a href="mailto:examenvanator@gmail.com">email</a>.</p>
  </div>
  </div>
);

export default TermsOfService;